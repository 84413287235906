import React, { useState } from 'react';

import './logo.scss';

const Logo = () => {
  const [hovering, setHover] = useState(false);
  const [fillColor, setFill] = useState('#000');

  return (
    <svg onClick={() => { document.location.href = '/'; }} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} id="logo" className={`animate ${hovering ? 'hover' : 'static'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.2 118" width="166.2" height="118" preserveAspectRatio="xMinYMin meet">
      <g>
        <rect id="stroke-back-2" x="132" opacity=".1" fill={fillColor} width="34.1" height="118" />
        <rect id="stroke-back-1" x="64.6" opacity=".1" fill={fillColor} width="35.4" height="118" />
        <path id="stroke-front-1" fill={fillColor} d="M34 115l-2 3H2c-2 0-2-1-2-3L65 3l4-3h31l-1 3-65 112z" />
        <polygon id="stroke-front-2" fill={fillColor} points="98.313,118 64.6,118 132.026,0 165.74,0" />
        <path id="stroke-front-3" fill={fillColor} d="M166 58l-2 2-31 55-2 3h32c2 0 3-1 3-3V58z" />
      </g>
    </svg>
  );
};

export default Logo;
