import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

// Styles
import './navigation.scss';

const navigationItems = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/about',
    label: 'Who is this guy?',
  },
  {
    link: '/blog',
    label: 'Things I wrote',
  },
  // {
  //   link: '/uses',
  //   label: 'Stuff I use.',
  // },
];

const Nav = () => {
  const [activePage, setActivePage] = useState('');
  const [isOpen, setOpen] = useState(false);

  return (
    <nav className={'site-navigation'} data-open={isOpen}>
      <div className="nav-wrap">
        <div className="nav-items">
          {
            navigationItems.map((item) => (
              <Link
                activeClassName={'active'}
                key={JSON.stringify(item)}
                to={item.link}
                >
                {item.label}
              </Link>
            ))
          }
        </div>
      </div>
    </nav>
  );
};

export default Nav;
