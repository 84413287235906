import React, { useState, useEffect } from 'react';

// Components
import Logo from './logo';
import Nav from '../navigation';

// Styles
import './header.scss';

const Header = () => {
  const [nowY, setNowY] = useState(0);
  const [lastY, setLastY] = useState(0);
  const [hide, setHide] = useState(false);

  const handleScroll = () => {
    if (window) {
      setNowY(window.scrollY);
    }
  };

  useEffect(() => {
    if (nowY > 50 && nowY > lastY && hide !== true) setHide(true);
    if (nowY < lastY && hide === true) setHide(false);
    setLastY(nowY);
  }, [nowY]);

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <header id="site-header" data-hide={hide} data-needs-bg={nowY > 50}>
      <Logo />
      <Nav />
    </header>
  );
};

export default Header;
