import React from 'react';

// Styles
import './reset.scss';
import './base.scss';

// Components
import Header from '../header';
import Footer from '../footer';

export default ({ animatedBg, children }) => {
  return (
    <>
      <div id="site-wrapper" className={animatedBg && 'animated'}>
        <div id="content-wrapper">
          <Header />
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};
