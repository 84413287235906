import React, { useMemo } from 'react';
import packageFile from '../../../package.json';

import './footer.scss';

const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);
  return (
    <footer id="site-footer">
      <div id="copyright"><span>&copy;</span> 2012-{year}</div>
      <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/maneeshchiba/">Linkedin</a>
      <a target="_blank" rel="noreferrer" href="https://codepen.io/maneeshc">Codepen</a>
      <div id="version">{packageFile.version}</div>
    </footer>
  );
};

export default Footer;
