import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import Bootstrap from '../../components/bootsrap';

import './blog.scss';

const BlogRoll = ({ data }) => {
  const renderPosts = () => {
    return data?.allMarkdownRemark?.edges.map(({ node }) => (
      <li key={`${node.frontmatter.title}-${node.frontmatter.date}`}>
        <Link
          to={node.frontmatter.path}
        >
          <time dateTime={node.frontmatter.rawDate}>{node.frontmatter.date}</time>
          <span className={'blog-title'}>{node.frontmatter.title}</span>
        </Link>
      </li>
    ));
  };

  return (
    <Bootstrap>
      <Helmet>
        <title>Blog 📝 | Maneesh Chiba</title>
      </Helmet>
      <section className="blogroll">
        <h1 className={'title'}>Blog Roll</h1>
        <ol>
          {renderPosts()}
        </ol>
      </section>
    </Bootstrap>
  );
};

export default BlogRoll;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          frontmatter {
            rawDate: date
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`;
